import React from 'react'
import ToggleButton from '../ToggleButton/ToggleButton'
import Logo from './Logo'
import classes from './Header.module.scss'


const Header = (props) => {
  return (
    <header className={classes.header}>
      <ToggleButton clickHandler={props.onThemeSwitch} theme={props.theme} />
      <Logo />
    </header>
  )
}

export default Header
import React from 'react'
import classes from './Title.module.scss' 

const SectionTitle = (props) => {
  return (
    <div className={classes['SectionTitle']}>
      <h2>{props.children}</h2>
    </div>
  )
}

export default SectionTitle
import React from 'react'
import Title from './Title/Title'
import LinkButton from '../LinkButton/LinkButton'

const Links = (props) => {
  return (
    <>
      <div className="row">
        <div className="col">
          <Title>{props.title}</Title>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          {
            // conditionally render and map through sign ups
            props.links && props.links.map((link, index) => {
              return (
                <LinkButton
                  title={link.title}
                  description={link.description}
                  icon={link.icon}
                  iconStyle={props.iconStyle}
                  buttonText={link.buttonText}
                  url={link.buttonUrl}
                  key={index}
                />
              )
            })
          }
        </div>
      </div>
    </>
  )
}

export default Links

import React from 'react'
import classes from './LinkButton.module.scss'
import Icon from '../Icon/Icon'

const LinkButton = (props) => {
  return (

    <div className={classes.pill}>
      {props.icon && <div className={classes.icon}><Icon path={props.icon.url} styleType={props.iconStyle} /></div>}
      <div className={classes.content}>
        <h3 className={classes.title}>{props.title}</h3>
        <p className={classes.description}>{props.description}</p>
      </div>
      <div className={classes.buttonWrapper}>
        <a href={props.url} className={classes.button}>{props.buttonText}</a>
      </div>
    </div>
  )
}

export default LinkButton
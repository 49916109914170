import React from 'react'
import classes from './ToggleButton.module.css'

const ToggleButton = (props) => {
  return (
    <div className={classes['toggle-wrapper']}>
      <span>{`${props.theme} mode`}</span>
      <label className={classes.switch}>

        <input type="checkbox" onClick={props.clickHandler}/>
        <span className={`${classes.slider} ${classes.round}`}></span>
      </label>
    </div>
  )
}

export default ToggleButton

import React, { useEffect, useState } from 'react'
import { GraphQLClient } from 'graphql-request'
import homePageQuery from './queries/home-page-query'
import './App.css'
import Header from './components/Header/Header'
import NextSteps from './components/NextSteps/NextSteps'
import Links from './components/Section/Links'
import SocialLinks from './components/SocialLinks/SocialLinks'
import Footer from './components/Footer/Footer'

function App() {

  const [theme, setTheme] = useState('light');

  const switchTheme = () => {
    setTheme(prevTheme => {
      return prevTheme === 'light' ? 'dark' : 'light'
    })
  }

  const [pageData, setPageData] = useState(null)

  // get page data from graphcms.com
  const fetchPageData = async () => {
    const url = process.env.REACT_APP_GRAPH_CMS_API_URL
    const client = new GraphQLClient(url, {
      headers: {
        "Authorization": "Bearer " + process.env.REACT_APP_GRAPH_CMS_TOKEN
      }
    })

    const data = await client.request(homePageQuery)
    setPageData(data.page)
  }

  useEffect(() => {
    fetchPageData()
  }, []);

  return (
    <div className="app" data-theme={theme}>
      <div className="container">
        <Header onThemeSwitch={switchTheme} theme={theme} />
        {pageData && <NextSteps nextSteps={pageData.nextSteps} />}
        {/* {pageData && <Links title="Christmas Sign Ups" links={pageData.christmasSignUps} iconStyle="signUp" />} */}
        {pageData && <Links title="Event Sign Up" links={pageData.eventSignUps} iconStyle="signUp" />}
        {pageData && <Links title="Helpful Links" links={pageData.helpfulLinks} iconStyle="signUp" />}
        <SocialLinks />
        <Footer />
      </div>
    </div>
  )
}

export default App

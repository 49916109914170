import React from 'react'
import classes from './Logo.module.scss'


const Logo = () => {
  return (
    <div className={classes.logo}>


      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1896.65 628.6">

        <path class="d" d="M314.3,0h0C140.72,0,0,140.72,0,314.3H0c0,173.58,140.72,314.3,314.3,314.3h0c173.58,0,314.3-140.72,314.3-314.3h0C628.6,140.72,487.88,0,314.3,0Zm-63.02,459.79h-15.34v-63.02h78.36c0,34.8-28.21,63.02-63.02,63.02Zm78.36-110.46h-93.69v-63.02h156.71c0,34.8-28.21,63.02-63.02,63.02Zm45.5-110.46H235.94v-63.02h202.21c0,34.8-28.21,63.02-63.02,63.02Z"></path>

        <path class="d" d="M729.18,219.91V98.78h88.8v27.24h-57.57v18.89h56.29v27.24h-56.29v47.76h-31.23Z"></path>
        <path class="d" d="M898.97,222.08c-9.32,0-17.92-1.54-25.79-4.63-7.87-3.09-14.71-7.38-20.52-12.89-5.81-5.51-10.35-12.1-13.62-19.79-3.27-7.69-4.9-16.13-4.9-25.33s1.63-17.64,4.9-25.33c3.27-7.69,7.81-14.28,13.62-19.79,5.81-5.51,12.65-9.81,20.52-12.89,7.87-3.09,16.46-4.63,25.79-4.63s17.77,1.54,25.7,4.63c7.93,3.09,14.77,7.39,20.52,12.89,5.75,5.51,10.26,12.11,13.53,19.79,3.27,7.69,4.9,16.13,4.9,25.33s-1.63,17.65-4.9,25.33c-3.27,7.69-7.78,14.29-13.53,19.79-5.75,5.51-12.59,9.81-20.52,12.89-7.93,3.09-16.5,4.63-25.7,4.63Zm0-27.6c4.96,0,9.47-.91,13.53-2.72,4.05-1.82,7.51-4.3,10.35-7.45,2.84-3.15,5.05-6.84,6.63-11.08,1.57-4.24,2.36-8.84,2.36-13.8s-.79-9.56-2.36-13.8c-1.57-4.24-3.79-7.93-6.63-11.08-2.85-3.15-6.3-5.63-10.35-7.45-4.06-1.82-8.57-2.72-13.53-2.72s-9.47,.91-13.53,2.72c-4.06,1.82-7.54,4.3-10.44,7.45-2.91,3.15-5.15,6.84-6.72,11.08-1.57,4.24-2.36,8.84-2.36,13.8s.79,9.56,2.36,13.8c1.57,4.24,3.81,7.93,6.72,11.08,2.91,3.15,6.38,5.63,10.44,7.45,4.05,1.82,8.56,2.72,13.53,2.72Z"></path>
        <path class="d" d="M1042.25,222.08c-9.69,0-18.13-1.27-25.33-3.81-7.2-2.54-13.17-6.08-17.89-10.62-4.72-4.54-8.26-9.93-10.62-16.16-2.36-6.23-3.54-13.1-3.54-20.61V98.78h31.78v71c0,7.26,2.15,13.2,6.45,17.8,4.3,4.6,10.68,6.9,19.16,6.9s14.68-2.3,18.98-6.9c4.3-4.6,6.45-10.53,6.45-17.8V98.78h31.6v71.91c0,7.51-1.15,14.41-3.45,20.7-2.3,6.3-5.81,11.71-10.53,16.25-4.72,4.54-10.65,8.08-17.8,10.62-7.14,2.54-15.56,3.81-25.24,3.81Z"></path>
        <path class="d" d="M1208.95,219.91l-51.21-70.1v70.1h-31.23V98.78h32.14l49.03,66.65V98.78h31.42v121.12h-30.14Z"></path>
        <path class="d" d="M1266.33,219.91V98.78h50.85c9.56,0,18.34,1.39,26.33,4.18,7.99,2.79,14.89,6.78,20.7,11.99,5.81,5.21,10.35,11.53,13.62,18.98,3.27,7.45,4.9,15.89,4.9,25.33s-1.63,17.89-4.9,25.33c-3.27,7.45-7.81,13.8-13.62,19.07-5.81,5.27-12.71,9.29-20.7,12.08-7.99,2.79-16.71,4.18-26.15,4.18h-51.03Zm50.85-27.24c5.33,0,10.08-.91,14.26-2.72,4.18-1.82,7.69-4.24,10.53-7.26,2.84-3.02,5.02-6.57,6.54-10.62,1.51-4.05,2.27-8.32,2.27-12.8,0-4.72-.7-9.08-2.09-13.07-1.39-4-3.48-7.51-6.27-10.53-2.79-3.02-6.27-5.39-10.44-7.08-4.18-1.69-9.05-2.54-14.62-2.54h-19.79v66.65h19.61Z"></path>
        <path class="d" d="M1471.35,219.91l-20.34-40.68h-15.98v40.68h-31.23V98.78h60.65c6.66,0,12.62,1.06,17.89,3.18,5.27,2.12,9.68,4.99,13.26,8.63,3.57,3.63,6.29,7.9,8.17,12.8,1.88,4.9,2.81,10.14,2.81,15.71s-.73,10.02-2.18,14.07c-1.45,4.06-3.33,7.57-5.63,10.53-2.3,2.97-4.87,5.42-7.72,7.35-2.85,1.94-5.72,3.39-8.63,4.36l24.7,44.49h-35.77Zm-11.62-67.92c4.24,0,7.81-1.12,10.71-3.36,2.91-2.24,4.36-5.48,4.36-9.72s-1.45-7.45-4.36-9.62-6.48-3.27-10.71-3.27h-24.7v25.97h24.7Z"></path>
        <path class="d" d="M1558.88,219.91v-48.85l-45.94-72.27h35.23l26.33,44.67,25.97-44.67h35.41l-45.76,72.27v48.85h-31.23Z"></path>
        <path class="d" d="M788.56,375.74c-9.32,0-17.98-1.52-25.97-4.54-7.99-3.02-14.92-7.29-20.79-12.8-5.87-5.51-10.47-12.1-13.8-19.79-3.33-7.69-4.99-16.19-4.99-25.51s1.66-17.82,4.99-25.51c3.33-7.69,7.93-14.28,13.8-19.79,5.87-5.51,12.8-9.77,20.79-12.8,7.99-3.02,16.64-4.54,25.97-4.54,7.26,0,13.74,.94,19.43,2.81,5.69,1.88,10.68,4.36,14.98,7.45,4.3,3.09,7.96,6.54,10.99,10.35,3.02,3.81,5.51,7.72,7.45,11.71l-26.88,12.89c-.97-2.42-2.33-4.69-4.09-6.81-1.76-2.12-3.79-4-6.08-5.63-2.3-1.63-4.78-2.91-7.45-3.81-2.66-.91-5.45-1.36-8.35-1.36-4.97,0-9.51,.88-13.62,2.63-4.12,1.76-7.69,4.21-10.71,7.35-3.03,3.15-5.36,6.87-6.99,11.17-1.63,4.3-2.45,8.93-2.45,13.89s.82,9.56,2.45,13.8c1.63,4.24,3.96,7.93,6.99,11.08,3.02,3.15,6.6,5.63,10.71,7.45,4.11,1.82,8.65,2.72,13.62,2.72,2.91,0,5.69-.45,8.35-1.36,2.66-.91,5.14-2.18,7.45-3.81,2.3-1.63,4.33-3.51,6.08-5.63,1.75-2.12,3.12-4.39,4.09-6.81l26.88,12.71c-1.94,3.88-4.42,7.75-7.45,11.62-3.03,3.88-6.69,7.35-10.99,10.44-4.3,3.09-9.29,5.6-14.98,7.54-5.69,1.94-12.17,2.91-19.43,2.91Z"></path>
        <path class="d" d="M918.95,375.74c-9.32,0-17.92-1.54-25.79-4.63-7.87-3.09-14.71-7.38-20.52-12.89-5.81-5.51-10.35-12.1-13.62-19.79-3.27-7.69-4.9-16.13-4.9-25.33s1.63-17.64,4.9-25.33c3.27-7.69,7.81-14.28,13.62-19.79,5.81-5.51,12.65-9.81,20.52-12.89,7.87-3.09,16.46-4.63,25.79-4.63s17.77,1.54,25.7,4.63c7.93,3.09,14.77,7.39,20.52,12.89,5.75,5.51,10.26,12.11,13.53,19.79,3.27,7.69,4.9,16.13,4.9,25.33s-1.63,17.65-4.9,25.33c-3.27,7.69-7.78,14.29-13.53,19.79-5.75,5.51-12.59,9.81-20.52,12.89-7.93,3.09-16.5,4.63-25.7,4.63Zm0-27.6c4.96,0,9.47-.91,13.53-2.72,4.05-1.82,7.51-4.3,10.35-7.45,2.84-3.15,5.05-6.84,6.63-11.08,1.57-4.24,2.36-8.84,2.36-13.8s-.79-9.56-2.36-13.8c-1.57-4.24-3.79-7.93-6.63-11.08-2.85-3.15-6.3-5.63-10.35-7.45-4.06-1.82-8.57-2.72-13.53-2.72s-9.47,.91-13.53,2.72c-4.06,1.82-7.54,4.3-10.44,7.45-2.91,3.15-5.15,6.84-6.72,11.08-1.57,4.24-2.36,8.84-2.36,13.8s.79,9.56,2.36,13.8c1.57,4.24,3.81,7.93,6.72,11.08,2.91,3.15,6.38,5.63,10.44,7.45,4.05,1.82,8.56,2.72,13.53,2.72Z"></path>
        <path class="d" d="M1111.07,373.56v-80.08l-30.51,80.08h-13.8l-30.69-80.08v80.08h-31.23v-121.12h43.4l25.42,66.83,25.24-66.83h43.4v121.12h-31.23Z"></path>
        <path class="d" d="M1275.78,373.56v-80.08l-30.51,80.08h-13.8l-30.69-80.08v80.08h-31.23v-121.12h43.4l25.42,66.83,25.24-66.83h43.4v121.12h-31.23Z"></path>
        <path class="d" d="M1391.64,375.74c-9.69,0-18.13-1.27-25.33-3.81-7.2-2.54-13.17-6.08-17.89-10.62-4.72-4.54-8.26-9.93-10.62-16.16-2.36-6.23-3.54-13.1-3.54-20.61v-72.09h31.78v71c0,7.26,2.15,13.2,6.45,17.8,4.3,4.6,10.68,6.9,19.16,6.9s14.68-2.3,18.98-6.9c4.3-4.6,6.45-10.53,6.45-17.8v-71h31.6v71.91c0,7.51-1.15,14.41-3.45,20.7-2.3,6.3-5.81,11.71-10.53,16.25-4.72,4.54-10.65,8.08-17.8,10.62-7.14,2.54-15.56,3.81-25.24,3.81Z"></path>
        <path class="d" d="M1558.34,373.56l-51.21-70.1v70.1h-31.23v-121.12h32.14l49.03,66.65v-66.65h31.42v121.12h-30.14Z"></path>
        <path class="d" d="M1615.72,373.56v-121.12h31.23v121.12h-31.23Z"></path>
        <path class="d" d="M1701.25,373.56v-93.88h-33.96v-27.24h98.97v27.24h-33.78v93.88h-31.23Z"></path>
        <path class="d" d="M1819.65,373.56v-48.85l-45.94-72.27h35.23l26.33,44.67,25.97-44.67h35.41l-45.76,72.27v48.85h-31.23Z"></path>
        <path class="d" d="M788.56,529.4c-9.32,0-17.98-1.52-25.97-4.54-7.99-3.02-14.92-7.29-20.79-12.8-5.87-5.51-10.47-12.1-13.8-19.79-3.33-7.69-4.99-16.19-4.99-25.51s1.66-17.82,4.99-25.51c3.33-7.69,7.93-14.28,13.8-19.79,5.87-5.51,12.8-9.77,20.79-12.8,7.99-3.02,16.64-4.54,25.97-4.54,7.26,0,13.74,.94,19.43,2.81,5.69,1.88,10.68,4.36,14.98,7.45,4.3,3.09,7.96,6.54,10.99,10.35,3.02,3.81,5.51,7.72,7.45,11.71l-26.88,12.89c-.97-2.42-2.33-4.69-4.09-6.81-1.76-2.12-3.79-4-6.08-5.63-2.3-1.63-4.78-2.91-7.45-3.81-2.66-.91-5.45-1.36-8.35-1.36-4.97,0-9.51,.88-13.62,2.63-4.12,1.76-7.69,4.21-10.71,7.35-3.03,3.15-5.36,6.87-6.99,11.17-1.63,4.3-2.45,8.93-2.45,13.89s.82,9.56,2.45,13.8c1.63,4.24,3.96,7.93,6.99,11.08,3.02,3.15,6.6,5.63,10.71,7.45,4.11,1.82,8.65,2.72,13.62,2.72,2.91,0,5.69-.45,8.35-1.36,2.66-.91,5.14-2.18,7.45-3.81,2.3-1.63,4.33-3.51,6.08-5.63,1.75-2.12,3.12-4.39,4.09-6.81l26.88,12.71c-1.94,3.88-4.42,7.75-7.45,11.62-3.03,3.88-6.69,7.35-10.99,10.44-4.3,3.09-9.29,5.6-14.98,7.54-5.69,1.94-12.17,2.91-19.43,2.91Z"></path>
        <path class="d" d="M941.83,527.22v-48.67h-50.3v48.67h-31.23v-121.12h31.23v45.22h50.3v-45.22h31.23v121.12h-31.23Z"></path>
        <path class="d" d="M1057.68,529.4c-9.69,0-18.13-1.27-25.33-3.81-7.2-2.54-13.17-6.08-17.89-10.62-4.72-4.54-8.26-9.93-10.62-16.16-2.36-6.23-3.54-13.1-3.54-20.61v-72.09h31.78v71c0,7.26,2.15,13.2,6.45,17.8,4.3,4.6,10.68,6.9,19.16,6.9s14.68-2.3,18.98-6.9c4.3-4.6,6.45-10.53,6.45-17.8v-71h31.6v71.91c0,7.51-1.15,14.41-3.45,20.7-2.3,6.3-5.81,11.71-10.53,16.25-4.72,4.54-10.65,8.08-17.8,10.62-7.14,2.54-15.56,3.81-25.24,3.81Z"></path>
        <path class="d" d="M1209.5,527.22l-20.34-40.68h-15.98v40.68h-31.23v-121.12h60.65c6.66,0,12.62,1.06,17.89,3.18,5.27,2.12,9.68,4.99,13.26,8.63,3.57,3.63,6.29,7.9,8.17,12.8,1.88,4.9,2.81,10.14,2.81,15.71s-.73,10.02-2.18,14.07c-1.45,4.06-3.33,7.57-5.63,10.53-2.3,2.97-4.87,5.42-7.72,7.35-2.85,1.94-5.72,3.39-8.63,4.36l24.7,44.49h-35.77Zm-11.62-67.92c4.24,0,7.81-1.12,10.71-3.36,2.91-2.24,4.36-5.48,4.36-9.72s-1.45-7.45-4.36-9.62-6.48-3.27-10.71-3.27h-24.7v25.97h24.7Z"></path>
        <path class="d" d="M1326.81,529.4c-9.32,0-17.98-1.52-25.97-4.54-7.99-3.02-14.92-7.29-20.79-12.8-5.87-5.51-10.47-12.1-13.8-19.79-3.33-7.69-4.99-16.19-4.99-25.51s1.66-17.82,4.99-25.51c3.33-7.69,7.93-14.28,13.8-19.79,5.87-5.51,12.8-9.77,20.79-12.8,7.99-3.02,16.64-4.54,25.97-4.54,7.26,0,13.74,.94,19.43,2.81,5.69,1.88,10.68,4.36,14.98,7.45,4.3,3.09,7.96,6.54,10.99,10.35,3.02,3.81,5.51,7.72,7.45,11.71l-26.88,12.89c-.97-2.42-2.33-4.69-4.09-6.81-1.76-2.12-3.79-4-6.08-5.63-2.3-1.63-4.78-2.91-7.45-3.81-2.66-.91-5.45-1.36-8.35-1.36-4.97,0-9.51,.88-13.62,2.63-4.12,1.76-7.69,4.21-10.71,7.35-3.03,3.15-5.36,6.87-6.99,11.17-1.63,4.3-2.45,8.93-2.45,13.89s.82,9.56,2.45,13.8c1.63,4.24,3.96,7.93,6.99,11.08,3.02,3.15,6.6,5.63,10.71,7.45,4.11,1.82,8.65,2.72,13.62,2.72,2.91,0,5.69-.45,8.35-1.36,2.66-.91,5.14-2.18,7.45-3.81,2.3-1.63,4.33-3.51,6.08-5.63,1.75-2.12,3.12-4.39,4.09-6.81l26.88,12.71c-1.94,3.88-4.42,7.75-7.45,11.62-3.03,3.88-6.69,7.35-10.99,10.44-4.3,3.09-9.29,5.6-14.98,7.54-5.69,1.94-12.17,2.91-19.43,2.91Z"></path>
        <path class="d" d="M1480.07,527.22v-48.67h-50.3v48.67h-31.23v-121.12h31.23v45.22h50.3v-45.22h31.23v121.12h-31.23Z"></path>
      </svg>


    </div>
  )
}

export default Logo
import React, { useState } from 'react'
import classes from './Icon.module.scss'

const Icon = (props) => {

  const [svgCode, setSvgCode] = useState('')

  // console.log(iconPath)

  async function getSvgCode(path) {
    await fetch(path)
      .then(response => response.text())
      .then(data => { setSvgCode(data) })
  }
  getSvgCode(props.path)

  //which css styles are needed
  let customClass = ['icon']

  if (props.styleType) {
    customClass = props.styleType
  }

  return <div className={`${classes.icon} ${classes[customClass]}`} dangerouslySetInnerHTML={{ __html: svgCode }}></div>

}

export default Icon
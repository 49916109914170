import React from 'react'
import NextStep from './NextStep'
import Title from '../Section/Title/Title'

const NextSteps = (props) => {
  return (
    <>
      <div className="row">
        <div className="col">
          <Title>Next Steps</Title>
        </div>
      </div>
      <div className="row">
        {props.nextSteps.map((step, index) => {
          return (
            <NextStep
              title={step.title}
              description={step.description}
              link={step.link}
              icon={step.icon}
              key={index}
            />
          )
        })}
      </div>
    </>
  )
}

export default NextSteps

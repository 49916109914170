import { gql } from 'graphql-request'

const homePageQuery = gql`
  query homePageContent {
    page(where: {id: "cl0ml9bd34uxx0cjrwoim53ha"}) {
      nextSteps {
        title
        description
        link
        icon {
          url
        }
      }
      eventSignUps {
        title
        description
        icon {
          url
        }
        buttonText
        buttonUrl
      }
      helpfulLinks {
        title
        description
        icon {
          url
        }
        buttonText
        buttonUrl
      }
      # christmasSignUps {
      #   title
      #   description
      #   icon {
      #     url
      #   }
      #   buttonText
      #   buttonUrl
      # }
    }
  }
`

export default homePageQuery
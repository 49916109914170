import React from 'react'
import classes from './Footer.module.scss'

const Footer = () => {

  const currentYear = new Date().getFullYear()

  return (
    <footer className={`${classes.footer} col-sm-12`}>
      <p>Copyright &copy; Foundry Community Church {currentYear}</p>
      <p>Company limited by guarantee with charitable status #10866897</p>
    </footer>
  )
}

export default Footer
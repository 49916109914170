import React from 'react'
import classes from './NextStep.module.scss'
import Icon from '../Icon/Icon'

const NextStep = (props) => {
  return (
    <a href={props.link} className={`col-sm-12 col-md-6 col-lg-4 ${classes.NextStep} `}>
      <div className={classes.inner}>
        <div className={classes['half-circle']}></div>
        {props.icon && <Icon path={props.icon.url} styleType="nextStep" />}
        <p className={classes.title}>{props.title}</p>
        <p className={classes.description}>{props.description}</p>
      </div>
    </a>
  )
}

export default NextStep